import React from "react";
import { subscribeKey } from "valtio/utils";

import { readState } from "@/__main__/app-state.mjs";
import { accountRefs } from "@/app/account.refs.mjs";
import { writeSettings } from "@/app/actions.mjs";
import eventBus from "@/app/app-event-bus.mjs";
import { appShellUserDropdownRefs } from "@/app/AppShellUserDropdown.refs.jsx";
import appRefs from "@/app/refs.mjs";
import { accountCardRefs } from "@/feature-auth/AccountCard.refs.jsx";
import type { User } from "@/feature-auth/models/user-model.mjs";
import { EVENT_USER_LOGOUT } from "@/feature-auth/utils/auth-actions.mjs";
import { AccountCardUserTag } from "@/feature-subscriber/AccountCardUserTag.jsx";
import { AccountMenuSubscriberItem } from "@/feature-subscriber/AccountMenuProItem.jsx";
import {
  clearUserData,
  initProState,
  removeProState,
} from "@/feature-subscriber/actions.mjs";
import { AppShellUserDropdownNameWrapper } from "@/feature-subscriber/AppShellUserDropdownNameWrapper.jsx";
import { AppShellUserDropdownWrapper } from "@/feature-subscriber/AppShellUserDropdownWrapper.jsx";
import {
  setupProOverlays,
  teardownProOverlays,
} from "@/feature-subscriber/game-overlay-pro-overlays.mjs";
import {
  buildOption,
  inGamePromo,
} from "@/feature-subscriber/lol-ingame-promo.mjs";
import { ProTile } from "@/feature-subscriber/ProTile.jsx";
import { SettingsUserImageContainer } from "@/feature-subscriber/SettingsUserImageContainer.jsx";
import { UserInfoTag } from "@/feature-subscriber/UserInfoTag.jsx";
import { isUserSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";
import { walletRefs } from "@/feature-wallet/refs.mjs";
import { BUILD_SORT } from "@/game-lol/constants/builds-constants.mjs";
import LoadingScreenRefs from "@/game-lol/LoadingScreen.refs.jsx";
import lolRefs from "@/game-lol/refs.mjs";
import { userInfoRefs } from "@/hub-settings/UserInfo.refs.jsx";
import routes, { appRoutes } from "@/routes/routes.mjs";
import { reRender } from "@/shared/EventedRender.jsx";
import matchTileAdRefs from "@/shared/match-tile-ad.refs.mjs";
import lazyFn from "@/util/lazy-fn.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";

const fetchUserSubscriberInfo = lazyFn(
  () => import("@/feature-subscriber/fetch-user-pro-info.mjs"),
);

const subscriberRoutes = [
  {
    path: "/premium",
    component: "feature-subscriber/Landing.tsx",
    fetchData: fetchUserSubscriberInfo,
  },
  {
    path: "/premium/subscription/landing-page",
    redirect: "/premium",
  },
  {
    path: "/pro",
    redirect: "/premium",
  },
];

const originals = mapOriginalRefs({
  lolRefs,
  LoadingScreenRefs,
  matchTileAdRefs,
  walletRefs,
  appRefsAccountMenuItems: appRefs.accountMenuItems,
  appShellUserDropdownRefs,
  userInfoRefs,
  accountCardRefs,
});

let unsubscribeUser: (() => void) | null = null;

export function setup() {
  initProState();
  eventBus.on(EVENT_USER_LOGOUT, clearUserData);

  // Routes
  routes.push(...subscriberRoutes);
  appRoutes.push(...subscriberRoutes);

  originals.set({
    appRefsAccountMenuItems: {
      slotOne: AccountMenuSubscriberItem,
    },
    appShellUserDropdownRefs: {
      UserDropdownWrapper: AppShellUserDropdownWrapper,
      UserNameWrapper: AppShellUserDropdownNameWrapper,
    },
    walletRefs: {
      ProTile,
    },
    userInfoRefs: {
      ImageContainer: SettingsUserImageContainer,
      UserTag: UserInfoTag,
    },
    accountCardRefs: {
      UserTag: AccountCardUserTag,
    },
  });

  originals.append({
    walletRefs: {
      fetchFunctions: [fetchUserSubscriberInfo],
    },
  });

  // LOL features
  lolRefs.inGameFeatures.content = inGamePromo;
  lolRefs.inGameFeatures.buildOption = buildOption;
  originals.set({
    lolRefs: {
      PostMatchJunglePathing: React.lazy(
        () => import("@/feature-subscriber/PostMatchJunglePathing.jsx"),
      ),
    },
  });

  // Handle user being set before pro intitializes
  handleUserChanged(readState.user);

  unsubscribeUser = subscribeKey(readState, "user", handleUserChanged);

  // All Game overlays
  setupProOverlays();

  reRender();
}

export function teardown() {
  // Routes
  subscriberRoutes.forEach((route) => {
    const i = routes.indexOf(route);
    const j = appRoutes.indexOf(route);
    routes.splice(i, 1);
    appRoutes.splice(j, 1);
  });

  // LOL features
  writeSettings(["lol", "buildSort"], BUILD_SORT.common);
  lolRefs.inGameFeatures.enabled = false;
  lolRefs.inGameFeatures.content = null;
  lolRefs.inGameFeatures.buildOption = null;

  originals.restore();
  unsubscribeUser?.();
  teardownProOverlays();

  eventBus.off(EVENT_USER_LOGOUT, clearUserData);
  accountRefs.activeSubscriber = false;
  removeProState();

  reRender();
}

function handleUserChanged(user: User) {
  const isSubscriber = isUserSubscriber(user);

  lolRefs.inGameFeatures.enabled = isSubscriber;
  accountRefs.activeSubscriber = isSubscriber;
}
