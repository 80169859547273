import React from "react";
import { styled } from "goober";

import { UserDropdownWrapper } from "@/app/AppShellUserDropdown.jsx";
import { useIsSubscriber } from "@/feature-subscriber/utils/subscriber.mjs";
import { classNames } from "@/util/class-names.mjs";

const WrappedUserDropdownWrapper = styled(UserDropdownWrapper)`
  &.subscriber {
    .nav-item--profile-icon {
      color: var(--subscriber);

      circle {
        fill: var(--subscriber);
      }

      path {
        fill: var(--subscriber);
      }
    }

    &:hover {
      .nav-item--profile-icon {
        circle {
          fill: var(--subscriber-gradient-15);
        }
      }
    }
  }
`;

export function AppShellUserDropdownWrapper({
  className,
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement>) {
  const isSubscriber = useIsSubscriber();

  return (
    <WrappedUserDropdownWrapper
      {...classNames(className, isSubscriber && "subscriber")}
      {...rest}
    >
      {children}
    </WrappedUserDropdownWrapper>
  );
}
